import { gSSP } from "app/blitz-server"
import { BlitzPage } from "@blitzjs/next"
import React, { ReactNode, Suspense } from "react"
import Layout from "app/core/layouts/Layout"
import LoadingSpinner from "app/components/LoadingSpinner"
import Hero from "app/components/PageComponents/home/Hero"
import Description from "app/components/PageComponents/home/Description"
import AccessDialogBox from "app/components/AccessDialogBox"
import UpgradeInterviewBanner from "app/components/UpgradeInterviewBanner"
import { homePageServerSideProps, DataProp } from "app/utils/divorceHomePage"
import { wrapper } from "app/redux/store"

export const getServerSideProps = gSSP(wrapper.getServerSideProps((store) => async (context) => {
  return homePageServerSideProps(context, store)
}))

interface PageDataType {
  children: ReactNode
  data: DataProp
}

const Tsdhome: BlitzPage = ({
  data = {
    hasProduct: true,
    expired: false,
    product: { id: -1, review: 0 },
    interviewMetaData: {
      completed_status: "",
      created_at: "",
      interview_id: 0,
      metadata: "",
      screen_status: "",
      title: "",
      updated_at: "",
      id: 0,
    },
  },
}: PageDataType) => {
  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        {<Hero product={data.product} />}
        {
          <UpgradeInterviewBanner
            product={data.product}
            interviewMetaData={data.interviewMetaData}
          />
        }
        {<Description />}
      </Suspense>
      <AccessDialogBox hasProduct={data.hasProduct} expired={data.expired} />
    </>
  )
}

Tsdhome.suppressFirstRenderFlicker = true
Tsdhome.authenticate = true
Tsdhome.getLayout = (page) => <Layout title="Home">{page}</Layout>

export default Tsdhome
